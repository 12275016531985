import React from 'react'
import '../components/styles/global.scss'
import Layout from '../components/common/Layout'
import { Link } from "gatsby"

export default function Grants() {
    return (    
        <Layout active='grants'
        title='PharmaEssentia Medical Affairs | Grants and IITs'
        description='PharmaEssentia’s active grant applications reflect our commitment to supporting independent research and medical education programs.'
        socialTitle='PharmaEssentia Medical Affairs | Grants and IITs'
        socialDescription='PharmaEssentia’s active grant applications reflect our commitment to supporting independent research and medical education programs.'
        pathname='/grants-and-iits'
        >
            <section>
                <div className='inner'>
                    <h1>Grants</h1>
                    <h3>Independent Medical Education Grant Request</h3>
                    <p>PharmaEssentia USA Corporation is committed to the support of advancing medical education for healthcare professionals to advance scientific understanding of our therapeutic areas of interest. Eligible grant requests may be for either accredited continuing education (CE) activities, such as continuing medical education (CME) activities, or nonaccredited CE activities that are scientifically balanced, nonpromotional, and address unmet medical and educational needs.</p>
                    <p className='bold'>Therapeutic areas of interest</p>
                    <ul className='bullets'>
                        <li>The identification and treatment of essential thrombocythemia and polycythemia vera</li>
                        <li>Current treatment strategies in myeloproliferative neoplasms (MPNs)</li>
                        <li>The role of interferons in the management of MPNs</li>
                        <li>MPNs in special populations, including children and adolescents/young adults</li>
                    </ul>
                    <p className='bold'>Applying for a medical education grant</p>
                    <p>All requests for funding should be submitted by email to <a className='text-link' href="mailto:grants@pharmaessentia.com?subject=Request%20grant">grants@pharmaessentia.com</a> at least 60 calendar days prior to the commencement of the educational activity for consideration. To be considered, please provide the required information outlined below:</p>
                    <ul className='bullets'>
                        <li>Grant request letter on official letterhead</li>
                        <li className='sub-bullet'>Contact information for the requesting individual and organization</li>
                        <li className='sub-bullet'>Include key details about the program</li>
                        <li className='sub-bullet'>Include the amount requested</li>
                        <li className='sub-bullet'>Identify if this request is associated with a scientific meeting</li>
                        <li>Fully itemized program budget</li>
                        <li>Documentation</li>
                        <li className='sub-bullet'>IRS W-9 Form (preferred) or W-8 Form</li>
                        <li className='sub-bullet'>Include a certificate of accreditation (if appropriate)</li>
                    </ul>
                    <p>The committee will review submitted grants and communications will occur via email. For questions about the status of your request, please email <a className='text-link' href="mailto:grants@pharmaessentia.com?subject=Request%20grant">grants@pharmaessentia.com</a>.</p>
                    <p className='bold'>Grant policies</p>
                    <ul className='bullets'>
                        <li>PharmaEssentia USA Corporation will not fund educational activities that are related to the promotion of a commercial product</li>
                        <li>Grants will not be awarded to individuals</li>
                    </ul>
                    <p>PharmaEssentia USA Corporation adheres to the current Accreditation Council for Continuing Medical Education guidelines and other industry best practices to ensure that the educational activity remains independent from PharmaEssentia USA Corporation.</p>
                    <h3>Investigator-Initiated Trial (IIT) Grant Requests</h3>
                    <p className='bold'>IIT mission and purpose</p>
                    <p>PharmaEssentia USA Corporation is working to redefine the potential of proven therapies to enhance the health and quality of life for patients living with hematologic diseases. Our commitment is supported not only through the clinical development programs within PharmaEssentia USA Corporation, but also through external independent research from investigators, organizations, and institutions. This program is open to all researchers who are interested in receiving financial and/or drug support for conducting independent medical and scientific research.</p>
                    <p className='bold'>Specific research areas of interest include:</p>
                    <ul className='bullets'>
                        <li>Mechanism of action (MOA)</li>
                        <li className='sub-bullet'>Inflammatory component</li>
                        <li className='sub-bullet'>Transcriptomics and genomics studies</li>
                        <li>Biomarkers</li>
                        <li className='sub-bullet'>Treatment</li>
                        <li className='sub-bullet'>Disease progression</li>
                        <li>Molecular response/allele burden/molecular response to disease (MRD)</li>
                        <li>Correlation of complete molecular response (CMR) to outcomes</li>
                        <li>Enhancing understanding of patient-centric benefits</li>
                        <li>Combination studies</li>
                        <li>Chronic myelomonocytic leukemia (CMML)</li>
                        <li>Clonal hematopoiesis of indeterminate potential (CHIP), low-risk, early disease</li>
                        <li>Solid tumor</li>
                        <li className='sub-bullet'>Hepatocellular carcinoma</li>
                        <li className='sub-bullet'>Melanoma</li>
                        <li className='sub-bullet'>Renal cell carcinoma</li>
                        <li>Bone marrow transplant, graft-versus-host disease (GVHD)</li>
                        <li>Human T-lymphotrophic virus type-1</li>
                    </ul>

                    <p className='bold'>Types of support considered</p>
                    <ul className='bullets'>
                        <li>Funding</li>
                        <li>Test article or placebo</li>
                        <li>Funding and test article or placebo</li>
                    </ul>
                    <p className='bold'>Types of research eligible for support</p>
                    <ul className='bullets'>
                        <li>Clinical studies of approved and unapproved uses involving PharmaEssentia compounds  </li>
                        <li>Preclinical studies (in vitro or animal studies) </li>
                        <li>Observational studies, such as epidemiology studies and certain outcomes research studies, where the primary focus is the scientific understanding of disease </li>
                        <li>Other types of independent research on disease states, including novel diagnostic screening tools and surveys, where PharmaEssentia has no direct commercial interest </li>
                    </ul>
                    {/* <p>Please reach out to your Medical Science Liaison (MSL) for questions about your research. If you don’t know your MSL, email your request to <a className='text-link' href="mailto:grants@pharmaessentia.com?subject=Request%20grant">grants@pharmaessentia.com</a> and we will put you in touch with them for next steps to submit your concept for review by the Investigation Review Committee (IRC). Once a concept is approved by the IRC, the investigator will be requested to share a protocol and budget for a final decision.</p> */}
                
                    <p>Please reach out to your Medical Science Liaison (MSL) for questions about your research or download <a className='text-link' target='_blank' rel='noopener noreferrer' href='downloads/PharmaEssentia_IIT_Concept_Submission_Form_v1.0_2023-03-31.pdf'>this form</a> and email your request to <a className='text-link' href="mailto:grants@pharmaessentia.com?subject=Request%20grant">grants@pharmaessentia.com</a>. Your local MSL will contact you for next steps to submit your concept for review by the Investigational Review Committee (IRC). Alternatively, you can find your local MSL <Link className='text-link' to='/resources/medical-information'>here</Link>. Once a concept is approved by the IRC, the investigator will be requested to share a protocol and budget for a final decision.</p>
                </div>
            </section>
        </Layout>
    )
}